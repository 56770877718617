<template>
  <div class="video-container">
    <v-row justify="center">
      <v-col cols="12" md="10" class="d-flex justify-center position-relative">
        <div class="video-wrapper">
          <video
            class="intro-video"
            :poster="posterImage"
            @click="openVideo"
          >
            <source :src="getVideo" type="video/mp4" />
            Your browser does not support the video tag.
          </video>

          <img
            class="play-button"
            :src="require('@/assets/play.png')"
            alt="Play Button"
            @click="openVideo"
          />
        </div>
      </v-col>
    </v-row>

    <full-screen-video-viewer
      :imageViewer="imageViewer"
      v-if="imageViewer.flag"
    />
  </div>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  name: "IntroVideoPdp",
  components: {
    FullScreenVideoViewer: () =>
      import("@/components/VideoPlayer/FullScreenImageViewer"),
  },
  data() {
    return {
      imageViewer: {
        src: "",
        flag: false,
        type: "video",
      },
      posterImage: require("@/assets/publicDonationPage/timelineEvent/introd1.png"),
    };
  },
  computed: {
    ...mapGetters({
      getCampaign: "publicDonationPage/getCampaign",
    }),
    getVideo() {
      return this.getCampaign.campaign_detail.introduction_video;
    },
  },
  methods: {
    openVideo() {
      this.imageViewer.src = this.getVideo;
      this.imageViewer.flag = true;
    },
  },
};
</script>

<style scoped>
.video-wrapper {
  position: relative;
  width: 100%;
  max-width: 900px;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
  transition: transform 0.3s ease;
  cursor: pointer;
}

.video-wrapper:hover {
  transform: scale(1.01);
}

.intro-video {
  width: 100%;
  height: auto;
  display: block;
  border-radius: 12px;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 70px;
  height: 70px;
  z-index: 2;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.play-button:hover {
  transform: translate(-50%, -50%) scale(1.1);
}

@media (max-width: 600px) {
  .play-button {
    width: 50px;
    height: 50px;
  }
}
</style>